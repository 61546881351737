import React from 'react'

function MyInbox() {
  return (
    <div>
      <h1 className="text-center mt-5">it is my inbox page</h1>
    </div>
  )
}

export default MyInbox
