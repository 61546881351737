import React from 'react'

function HelpCenter() {
    return (
        <div>
            <h1 className="text-center mt-5">Its is help center page</h1>
        </div>
    )
}

export default HelpCenter
