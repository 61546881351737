import React from 'react'

function Saved() {
    return (
        <div>
            <h1 className="text-center mt-5">It is saved data page</h1>
        </div>
    )
}

export default Saved
