import React from 'react'
import UserCard from "../../UserProfile OR booking information/userCard/UserCard"
import UserProfilePage from "../../UserProfile OR booking information/userProfile-page/UserProfilePage"


function MyBooking() {
  return (
    <div>
      <UserCard/>
      <UserProfilePage/>
    </div>
  )
}

export default MyBooking
