import React from 'react'
import Form1 from "./RegisterPage-1/Form1"
// import VerificationPage from './Verification-code_Page/VerificationPage'
// import Form2 from "../Register_Log-in-page/Account_Information/Form2"
// import AdditionalInformation from './Additional-Information-part/AdditionalInformation'

function RegistrationPage() {
    return (
        <div>
            <Form1 />
        </div>
    )
}

export default RegistrationPage
