import React from 'react'
import TrainBannerr from "../trainSearchBanner/images/Train-Banner.png"

function TrainBanner() {
    return (
        <div>
            <img src={TrainBannerr} width="100%" alt="" className="img-fluid"></img>
        </div>
    )
}

export default TrainBanner
