import React from "react";
import BookingStepperContainer from "./BookingStepperContainer/BookingStepperContainer";

function BookingSteper_Combine() {
  return (
    <div>
      <BookingStepperContainer />
    </div>
  );
}

export default BookingSteper_Combine;
