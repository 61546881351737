import React from "react";
import LaunchBanr from "../LaunchBanner-part/images/Launch-Search-Page-Banner.png";

function LaunchBanner() {
  return (
    <div>
      <img src={LaunchBanr} width="100%" alt="" className="img-fluid"></img>
    </div>
  );
}

export default LaunchBanner;
